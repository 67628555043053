import request from '@/core/services/api.services.request'

export function getAllEmployees(params) {
  return request({
    url: '/user/list',
    method: 'get',
    params: params
  })
}

export function getEmployeeDetails(employee_id) {
  return request({
    url: '/user/view/'+employee_id,
    method: 'get'
  })
}

export function deleteEmployee(employee_id) {
  return request({
    url: '/user/delete/'+employee_id,
    method: 'delete'
  })
}

export function getRoleList() {
  return request({
    url: '/role/list',
    method: 'get',
  })
}

export function changeStatus(employee_id, status) {
  return request({
    url: '/user/change/status/' + employee_id + '/' + status,
    method: 'post',
  })
}

export function getPasswordCheck(password) {
  return request({
    url: '/user/check/password/' + password,
    method: 'get',
  })
}

export function getEmployeeId() {
  return request({
    url: 'user/employee/id',
    method: 'get',
  })
}

export function getEmployeeCheckInId() {
  return request({
    url: 'employee/checkIn/id',
    method: 'get',
  })
}