<!-- Settings -->
<template>
    <div>
        <div class="m-bot-10">
          <div class="row">           
            <div class="col-sm-6 col-xs-12">
              <b-form-group class="mb-3">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Type to Search"
                    @keyup.enter.native="(filter)?fetchData(1):''" 
                    auto-complete="nope"
                  ></b-form-input>
                  <span class="search-icon" v-if="filter" id="clear-button">
                     <i class="fas fa-times" @click="filter =''"></i>
                     <b-tooltip target="clear-button" variant="primary">Clear</b-tooltip>
                  </span>
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="(filter)?fetchData(1):''" variant="primary">Search</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
             <div class="col-sm-6 col-xs-12 text-right">
              <b-button class="rounded-3 btn btn-sm btn-primary font-weight-bold text-uppercase" variant="primary" @click="createEmployee"><i class="fa fa-plus"></i>Add Employee
              </b-button>              
            </div>
          </div>
        </div>
         <div class="row">
          <div class="col-sm-12">
            <el-table :data="list" border element-loading-text="Loading" style="width: 100%" v-loading="loading">
              <el-table-column fixed align="center" label='Employee#' prop="id" sortable width="120" >
                <template slot-scope="scope">
                <el-tag slot="reference" size="mini"><a href="#" @click.prevent="viewEmployee(scope.row.id)">{{(scope.row.emp_id) ? scope.row.emp_id : ""}}</a></el-tag>
                </template>
              </el-table-column>
                <el-table-column label="CheckIn ID" prop="employee_check_id"  width="160" sortable >
                <template slot-scope="scope">
                <span>{{(scope.row.employee_check_id) ? scope.row.employee_check_id : ""}}</span>
                </template>
              </el-table-column>
              <el-table-column label="Employee Name" prop="first_name"  sortable>
                <template slot-scope="scope">
                <span>{{scope.row.first_name +' '+ (scope.row.last_name?scope.row.last_name:'')}}</span> 
                </template>
              </el-table-column>              
              <el-table-column label="Email" prop="email" sortable width="150">
                <template slot-scope="scope">
                <span>{{(scope.row.email) ? scope.row.email : ""}}</span>
                </template>
              </el-table-column>
              <el-table-column label="Phone No" prop="phone_number"  width="130" sortable >
                <template slot-scope="scope">
                <span>{{(scope.row.phone_number) ? scope.row.phone_number : ""}}</span>
                </template>
              </el-table-column>            
              <el-table-column label="Role" prop="role" sortable width="120" >
                <template slot-scope="scope">
                <span>{{(scope.row.role.role) ? scope.row.role.role : ""}}</span>
                </template>
              </el-table-column>
              <el-table-column label="Status" align="center" prop="active" sortable width="100" >
                <template slot-scope="scope" >
                  <div v-if="user.role_id != 1">
                  <span v-if="scope.row.active == 1" class="active">Active</span>
                  <span v-if="scope.row.active == 0" class="red">Deactive</span>
                  </div>
                  <div v-else>
                    <el-switch :active-value="1" :inactive-value="0"  v-model="scope.row.active"  @change="updateStatus(scope.row.id, scope.row.active)" style="margin:0px;" v-if="scope.row.id != user.user_id" ></el-switch>
                  </div>
                </template>
               
              </el-table-column>
              <el-table-column fixed="right" align="center" label="Actions" width="80">
                <template slot-scope="scope">
                  <el-dropdown @command="handleActions($event,scope.row)" size="medium">
                    <i class="flaticon2-menu-1"></i>
                    <el-dropdown-menu slot="dropdown" class="action-dropdown">
                      <el-dropdown-item :command="1" ><i class="fa fa-eye"></i>View</el-dropdown-item>                      
                      <el-dropdown-item :command="2" v-if="user.role_id == 1"><i class="fa fa-pen"></i>Edit</el-dropdown-item>
                      <el-dropdown-item :command="2" v-if="user.user_id == scope.row.id && user.role_id != 1"><i class="fa fa-pen"></i>Edit</el-dropdown-item>
                      <el-dropdown-item :command="3" v-if="user.role_id == 1"><i class="fa fa-trash"></i>Delete</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>
         </div>       
        <div class="row">
          <div class="col-sm-12">
            <el-pagination v-if="!loading" style="margin-top:15px;"
                background
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="fetchData" 
                :page-count="pagination.last_page" 
                :page-sizes="[10, 25, 50, 100]"
                :page-size="pagination.per_page"
                :current-page.sync="pagination.current_page" 
                :total="pagination.total_rows"
                >
            </el-pagination>
          </div>
        </div>        
<v-dialog/>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {getAllEmployees, deleteEmployee, changeStatus} from "@/api/employee";
import JwtService from "@/core/services/jwt.service";


  export default {
    name: "list-employee",
    data() {
      return {
        list: [],      
         pagination:{
          total_rows: 1,
          current_page: 1,
          last_page:null,
          per_page: 10,
        },
        filter: null,
        filterOn: [],
        user: []
      }
    },
    watch:{
      filter : function(n){
        if(!n){
          this.fetchData(1)
        }
      }
    },
    computed: {
    
    },
    components: {
        
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/dashboard" },
        { title: "Employees", route: "/employee/list" },       
        
      ]);
    },
    created(){
      if(JwtService.getAttribute('user_data')){
        this.user = JSON.parse(JwtService.getAttribute('user_data'));
        
      }
      this.fetchData(1);
    },
    methods:{
      sortName(a,b) {
        if (a.name.toUpperCase() <  b.name.toUpperCase()) return -1;
       if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
       return 0;
 },
      updateStatus(emp_id,status){       
        changeStatus(emp_id,status).then(response => {
          this.$showResponse('success', response.data.message);
        })},

      fetchData(p) {
        this.loading = true
        let params = { page: p }
        
        if(this.filter){
          params.filter = this.filter
        }
        if(this.sort){
          params.sort = this.sort;
        }
        if(this.pagination.per_page){
          params.per_page = this.pagination.per_page;
        }
        if(this.selectedWebsite){
          params.selected_website = this.selectedWebsite;
        }
        getAllEmployees(params).then(response => {
          this.list = response.data.data.data
          this.pagination.current_page = response.data.data.current_page
          this.pagination.last_page = response.data.data.last_page
          this.pagination.total_rows = response.data.data.total
          this.loading = false
          this.$set(this.list, '_showDetails', true)
        })
      },
      createEmployee(){
        this.$router.push({path:'/employee/create'})
      },
      viewEmployee(id){
        this.$router.push({path:'/employee/view/'+id})
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      handlePageChange(p){
        this.fetchData(p)
      },
       handleSizeChange(per_page){
        this.pagination.per_page = per_page;
        this.fetchData(1);
      },
      handlePerPageChange(){
        this.fetchData(1)
      },
      handleActions(evt, row)
      {
          if (evt === 1){
            this.$router.push({path:'/employee/view/'+row.id})
          }
          else if (evt === 2){
            this.$router.push({path:'/employee/edit/'+row.id})
          }
          else if (evt === 3){
            this.$bvModal.msgBoxConfirm('Are you sure you want to delete ?', {
              title: 'Please Confirm',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            }).then(value => {
              if(value){
                this.loading = true;
                deleteEmployee(row.id).then(response => {
                  if(response.data.status){
                      this.fetchData(1)
                      this.$showResponse('success', response.data.message);
                  }
                  this.loading = false;
                })
              }else{
                return false;
              }
            })
          }
        },
    }
  }
</script>